import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// COMPONENTES
import Menu from './Menu';
import CoberturaModal from '../modal/CoberturaModal';
import axios from 'axios';
import * as Constants from '../../constants/global';

// REDUX
import { selectActiveUser } from '../../store/usuario/reducer';
import { selectActiveCarrito } from '../../store/carrito/reducer';
import ActualizarCarrito from '../../store/carrito/action';

// IMAGENES
import anddress from '../../assets/icon/address.svg';
import perfil from '../../assets/icon/perfil.svg';
import carrito from '../../assets/icon/carrito.svg';
import Auth from '../../helpers/Auth';
import CargarContenido from '../util/CargarContenido';
import AlertModal from '../modal/AlertModal';
import ReferenciaComercial from '../modal/ReferenciaComercial';
import { selectActiveAlert } from '../../store/alerta/reducer';

const Sub_nav = (props) => {

    const [cantidad, setCantidad] = useState(0);
    const [modalCobertura, setModalCobertura] = useState(false);
    const [sucursal, setSucursal] = useState(false);
    const [sucursales, setSucursales] = useState([]);
    const [alerta, setAlerta] = useState();

    const handleMenu = () => {
        document.querySelector(".menu").classList.toggle("show-menu");
    }

    const handleRedirect = item => {
        props.history.push(item);
    }

    const getCantidad = () => {
        var cant = 0;
        let cart = props.carrito || {};
        Object.keys(cart).map((item, i) => {
            cant += cart[item].cantidad;
        })
        setCantidad(cant);
    }

    const showCobertura = () => {
        if(Object.keys(Auth.getCarrito()).length>0){
            setAlerta({
                tipo: "info",
                titulo: "Sucursal",
                mensaje: "Actualmente tienes productos en tu carrito, si cambias de sucursal se limpiara el carrito",
                btnOk: "Aceptar",
                btnCancel: "Cancelar",
                fnOk: function () { 
                    if (Auth.getDoc()) {
                        getSucursales();
                    }
                },
                fnCancel: function(){
    
                }
            })
        }else{
            if (Auth.getDoc()) {
                getSucursales();
            }
        }
       
    }

    useEffect(() => {
        /*setTimeout(function () {
            var sucursal = Auth.getSucursal();
            if (Object.keys(sucursal).length > 0) {
                setSucursal(sucursal);
            }
        }, 3000);*/
        var sucursal = Auth.getSucursal();
            if (Object.keys(sucursal).length > 0) {
                setSucursal(sucursal);
            }
        getCantidad();
    }, [props.carrito])

    const getSucursales = () => {
        let url = Constants.URL_BASE + Constants.WSSUCURSALES + "?usuario=" + Auth.getDoc() + "&tipoUsuario="+Auth.getTipoUsuario();

        axios.get(url).then(res => {
            console.log(res.data);
            let data = res.data || [];
            setSucursales(data);
            if (data.length > 1) {
                setModalCobertura(true);
            } else {
                Auth.setSucursal({
                    ciudad: data[0].ciudad,
                    codListaP: data[0].codListaP,
                    codigoSac: data[0].codigoSac,
                    direccion: data[0].direccion,
                    nombre: data[0].nombre,
                    razonSocial: data[0].razonSocial,
                    nit: data[0].nit,
                    condicionPago: data[0].condicionPago,
                    cupo: data[0].cupo,
                    codDiasEntrega: data[0].codDiasEntrega,
                    pedidoMinimo: data[0].pedidoMinimo,
                    pedidoMaximo: data[0].pedidoMaximo,
                    bloqueado: data[0].bloqueado,
                    agencia :  data[0].agencia,
                    grupoCliente :  data[0].grupoCliente,
                    obsequiPrimerPed : data[0].obsequiPrimerPed,
                    bloqueadoCartera: 'N'
                });
                setSucursal(data[0]);
                setModalCobertura(false);
                window.location.reload(true);
            }
        })
    }


   

    return (
        <React.Fragment>
            <div className="navmenu">
                <ul id="menu">
                   {Auth.getDoc() !=='1111111111'&&<li className="location">
                        <a className="tab"
                            onClick={() => showCobertura()}>
                            <img src={anddress} alt="icon menu" width="20px" height="20px"></img>
                            <p>
                                {sucursal ? sucursal.codigoSac : "SIN UBICACIÓN"}
                                <br />
                                {sucursal ? sucursal.direccion: ""}
                                <br/>
                                {sucursal ? <span>{sucursal.condicionPago !== "DI" ? "Crédito" : "Contado"} {sucursal.condicionPago !== "DI" ?
                                        " - $" + new Intl.NumberFormat("en", {
                                            numberingSystem: "latn",
                                            style: "decimal",
                                            maximumFractionDigits: 0,
                                            currency: "COP"
                                        }).format(sucursal.cupo) : ""}</span> : ""}
                                </p>
                                
                        </a>
                    </li>}
                    {/*  {sucursal ? (
                        <li className="location">
                            <a className="tab"
                                style={{ backgroundColor: props.color, opacity: "0.8" }}>
                                {sucursal.condicionPago !== "DI" ? "Credito" : "Contado"}
                                <br />
                                {sucursal.condicionPago !== "DI" ? sucursal.cupo : ""}
                            </a>
                        </li>
                    ) : (null)} */}
                    <li className="profile">
                        <a className="tab"
                            onClick={() => handleMenu()}
                            style={{ backgroundColor: props.color, opacity: "0.8" }}>
                            <img src={perfil} alt="icon menu" width="15px" height="15px"></img>
                            {sucursal.nombre ?
                                <p>{sucursal.nombre}
                                    <br />
                                   {/*  {sucursal.condicionPago !== "DI" ? "Crédito" : "Contado"}  {sucursal.condicionPago !== "DI" ?
                                        " $" + new Intl.NumberFormat("en", {
                                            numberingSystem: "latn",
                                            style: "decimal",
                                            maximumFractionDigits: 0,
                                            currency: "COP"
                                        }).format(sucursal.cupo) : ""}*/}
                                </p>
                                : <CargarContenido carrito={3} />}

                        </a>
                    </li>
                    <li>
                        <a className="tab cart"
                            onClick={() => handleRedirect('/carrito')}>
                            <img src={carrito} alt="icon menu" width="25px" height="25px"></img>
                            {cantidad > 0 ? (
                                <span className="cant">{cantidad}</span>
                            ) : null}
                        </a>
                    </li>
                </ul>
            </div>
            <Menu history={props.history} />
            { alerta ? (<AlertModal alerta={alerta} updateAlerta={setAlerta} />) : null}
            { modalCobertura ? (<CoberturaModal usuario={props.usuario} sucursales={sucursales} updateSucursal={setSucursal} showModal={setModalCobertura} />) : null}
            { props.comprobante ? (<ReferenciaComercial sucursales={sucursales}  />) : null}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        usuario: selectActiveUser(state),
        carrito: selectActiveCarrito(state),
        comprobante: selectActiveAlert(state)
    }
}


export default withRouter(connect(mapStateToProps, { ActualizarCarrito })(Sub_nav));